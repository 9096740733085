<script setup lang="ts">
import { defineAsyncComponent, computed, ref, watch } from 'vue'
import { useRoute } from 'vue-router/composables'
import { ActivityItem, ApiResponseData } from '/~/types/api'
import api from '/~/core/api'
import ui from '/~/core/ui'
import BaseAsidePage from '/~/components/base/aside-page/base-aside-page'
import BaseLoader from '/~/components/base/loader/base-loader.vue'
import { Activity } from '/~/composables/activity'
import ActivityDetailsV15 from './activity-details.vue'

const ActivityDetails = defineAsyncComponent(
  () =>
    import(
      '/~/templates/bill-payments/views/activity/details/activity-details.vue'
    )
)
const ActivityDetailsRefund = defineAsyncComponent(
  () =>
    import(
      '/~/templates/bill-payments-v1_5/views/activity/components/activity-details-refund.vue'
    )
)

const props = defineProps<{
  id: string
}>()

const item = ref<Activity>()

const route = useRoute()

const title = computed(() => {
  if (!item.value?.status) {
    return 'Payment details'
  }
  switch (item.value?.status) {
    case 'completed':
    case 'completed_with_remittance_failures':
      return 'Payment successful'

    case 'pending':
    case 'remittance_pending':
      return 'Payment pending'
    case 'cancelled':
      return 'Payment cancelled'
    case 'refunded':
      return 'Payment returned'
    default:
      return 'Payment failed'
  }
})

const isV15Activity = computed(() => {
  return (
    item.value?.isTypePaymentOrder ||
    item.value?.isTypeStatementOrder ||
    item.value?.isTypeBatchOrder
  )
})

const isRefundActivity = computed(() => item.value?.isTypeRefund)

async function fetchActivityItem(id: string) {
  const url = `/v3/activity/${id}`

  const result = await api.get<ApiResponseData<ActivityItem>>(url, {
    baseURL: eonx.hosts.api.v1_1,
  })
  const activityItem = new Activity(result.data)

  return activityItem
}

async function init() {
  item.value = await fetchActivityItem(props.id)
}

init()

watch(
  () => route.params.id,
  (newId) => {
    if (newId) {
      item.value = undefined
      init()
    }
  }
)
</script>

<template>
  <div v-if="!item" class="flex h-full w-full items-center justify-center">
    <base-loader />
  </div>
  <div v-else-if="!ui.mobile" class="mx-auto max-w-2xl">
    <activity-details-v15 v-if="isV15Activity" :id="id" />
    <activity-details-refund v-else-if="isRefundActivity" :activity="item" />
    <activity-details v-else :id="id" />
  </div>
  <base-aside-page
    v-else
    :title="title"
    :back="{
      name: 'activity',
    }"
    no-padding
    shadow
  >
    <div>
      <activity-details-v15 v-if="isV15Activity" :id="id" />
      <activity-details-refund v-else-if="isRefundActivity" :activity="item" />
      <activity-details v-else :id="id" />
    </div>
  </base-aside-page>
</template>
